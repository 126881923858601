.contact {
    font-size: 1.2em;
}

.contact li {
    margin-bottom: 0.2em;
    margin-top: 0.2em;
}




@include breakpoint("<=xsmall"){

  #header {
      padding-top: 3em;
      padding-bottom: 1em;
  }

}
