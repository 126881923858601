.cv {
    width: 100%;
    padding-right: 3rem;

    @include breakpoint("<=small"){

      padding-right: 0;
    }
}

.year {

    @include breakpoint(">medium"){
        text-align: right;
    }
}


@include breakpoint("<=small"){
  ul.alt li:last-child {
      border-bottom: solid 2px #efefef;
  }

}
@include breakpoint(">=large") {

    #header {
        padding-bottom: 2em;
        padding-right: 2.5em;
    }
}

#footer {
    padding-top: 2em;
    padding-bottom: 2em;
}


h1 {
  color: #50BF97;
}

#main > section {
  margin-top: 3rem;
}

.link :hover{
  background-color: #ededed;
}

.link {
  padding: 0 !important;
}

.link a {
  display: block;
  color: inherit !important;
  padding: 0.5em 0.5em 1em 0.5em;
  border-bottom: 0;
}

.link:first-child a {
  padding-top: 0px;
}

.no-link {
  padding: 0.5em 0.5em 1em 0.5em !important;
}


.daterow {
  margin-left: 0em;

}

.daterow div {
  padding-left: 0;
}


.cv h3 {
    margin-bottom: 0;
}

.cv h2 {
    margin-bottom: 0;
}

.project h2 {
    margin-bottom: 0;
}

.cv p {
    margin-top: 0.6rem;
    margin-bottom: 0;
}

.address {
  line-height: 3rem;
}
